import React from "react"
import Layout from "../../components/Layout"
import ContentBox from "../../components/Content-box"
import PageTitle from "../../components/PageTitle"

const Huisregels = () => {
  return (
    <Layout title="Huisregels">
      <PageTitle title="Huisregels" />
      <ContentBox>
        <h3>Huisregels</h3>
        <ul>
          <li>breng uw afsprakenkaartje mee bij iedere behandeling.</li>
          <li>breng uw handdoek mee.</li>
          <li>
            lees uw verzekeringspolis door wat betreft uw budget fysiotherapie,
            zodat u niet voor verrassingen komt te staan voor ongedekte zorg.
          </li>
          <li>
            lees uw verzekeringspolis door wat betreft de
            verzekeringsvoorwaarden fysiotherapie.
          </li>
          <li>
            neem in de wachtruimte plaats. De therapeut komt u persoonlijk
            halen.
          </li>
          <li>bespreek uw verwachtingen en voortgang van de behandelingen.</li>
          <li>
            het privacy-, klachtenreglement en de betalingsvoorwaarden kunt u
            bij de therapeut opvragen.
          </li>
        </ul>
        <p>
          Uw afspraak dient u uiterlijk 24 uur van te voren op te zeggen. Indien
          u uw afspraak niet 24 uur van te voren afzegt, wordt u een bedrag van
          €25,- no-show particulier in rekening gebracht.
        </p>
      </ContentBox>
    </Layout>
  )
}

export default Huisregels
